<template>
  <div class="offer-block">
    <b-row v-if="getMediaOffer.length > 0">
      <b-col cols="12" class="offer-image-wrapper">
        <div class="offer-header">
          <mini-slider
            :imageResolution="'1400x400'"
            :mediaList="getMediaOffer"
            @sliderControl="sliderChanged($event)"
            :aspect="isMobile ? '4:3' : '3:1'"
            :currentIndex="{
              index: galleryIndex,
              interval: sendInterval
            }"
            :ref="'miniOfferSlider' + index"
            :uniqueIndex="'offer-' + index"
            :video-ratio="isMobile ? '4by3' : '3by1'"

          ></mini-slider>
          <div class="offer-title" v-html="getOfferTitle"></div>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-3" :class="{ 'mt-md-5': getMediaOffer.length > 0 }">
      <b-col
        v-if="getMediaOffer.length > 0"
        cols="12"
        md="5"
        class="offer-gallery pl-0 order-1 order-md-2"
      >
        <b-row class="no-gutters justify-content-end">
          <b-col
            v-for="(media, index) in mediaListArr"
            class="offer-gallery-preview-wrapper"
            :key="index"
            cols="3"
            :class="{
              active:
                media.index === galleryIndex ||
                (media.index ===
                  mediaListArr.findLast((m) => m.scope === 'gallery')?.index &&
                  gallery.some((img) => img.index === galleryIndex))
            }"
            @mouseenter="selectImage(media.index)"
            @mouseleave="mouseOut(media.index)"
            @click="showLightbox(media.index)"
          >
            <div
              class="offer-gallery-preview-image"
              :style="{
                'background-image':
                  'url(' + buildMediaURL(getMediaUrl(media), '150x110') + ')'
              }"
            >
              <b-aspect aspect="4:3"></b-aspect>
            </div>
          </b-col>
        </b-row>
      </b-col>
      <b-col
        cols="12"
        :md="getMediaOffer.length > 0 ? '7' : '12'"
        class="offer-description order-2 order-md-1 mt-4 mt-md-0"
      >
        <h4
          v-if="getMediaOffer.length === 0"
          v-html="getOfferTitle"
          class="offer-title font-weight-bold"
        ></h4>
        <input
          type="checkbox"
          class="expanded_checkbox"
          :id="'expanded' + index"
        />
        <TruncateText
          :max-length="truncateLength"
          :content="offerData[0].description"
        >
          <template #showMore>
            <label
              :for="'expanded' + index"
              role="button"
              class="offer-show-more"
            >
              {{ $t("core.read_more") }}
            </label>
          </template>
        </TruncateText>
      </b-col>
    </b-row>
  </div>
</template>

<script setup>
import { useBreakpoints } from "@/composables/useBreakpoints";
import { computed } from "vue";

const { isMobile } = useBreakpoints();
const truncateLength = computed(() => (isMobile.value ? 150 : 400));
</script>
<script>
import MiniSlider from "@/components/blocks/MiniSlider";
import { lpMixins } from "@/mixins/lpMixins";
import TruncateText from "@/components/TruncateText.vue";
import { findLast } from "lodash";

export default {
  name: "Offer",
  mixins: [lpMixins],
  components: { TruncateText, MiniSlider },
  props: ["offerData", "index", "selectedVariantIndex"],
  data() {
    return {
      sendInterval: 4000,
      currentIndex: 0,
      galleryIndex: 0,
      descriptionMaxLength: 540,
      timeoutStarted: {
        theTimeout: null
      },
      gallery: []
    };
  },
  computed: {
    getOfferTitle() {
      return this.offerData.length == 1 ? this.offerData[0].title : "";
    },
    getMediaOffer() {
      let order = ["picture", "gallery", "video"];
      let mediaList = this.offerData[0].media.map((media) => {
        media.customOrder = order.indexOf(media.scope);
        return media;
      });
      return mediaList.sort((a, b) => {
        if (a.customOrder > b.customOrder) return +1;
        if (a.customOrder < b.customOrder) return -1;
        return 0;
      });
    },
    mediaListArr() {
      const order = ["picture", "gallery", "video"];
      const mediaList = this.offerData[0].media
        .map((media) => ({
          ...media,
          customOrder: order.indexOf(media.scope)
        }))
        .sort((a, b) => a.customOrder - b.customOrder)
        .map((media, i) => ({
          ...media,
          index: i
        }));
      let orderedMediaList = [];

      // Check if has a picture
      const mainPicture = mediaList.find(
        ({ scope }) => scope === "picture"
      );
      if (mainPicture) orderedMediaList.push(mainPicture);

      // Check if has a video
      const video = mediaList.find(({ scope }) => scope === "video");
      if (video) orderedMediaList.push(video);

      // Check and manipulate gallery
      const gallery = mediaList.filter(({ scope }) => scope === "gallery");
      if (gallery.length) {
        const count = 4 - orderedMediaList.length;
        orderedMediaList = orderedMediaList.concat(gallery.slice(0, count));
        this.gallery = [...gallery.slice(count)];
      }

      orderedMediaList.sort((a, b) => a.customOrder - b.customOrder);
      return orderedMediaList;
    },
  },
  methods: {
    getMediaUrl(media) {
      if (
        media.index ===
        findLast(this.mediaListArr, (m) => m.scope === "gallery")?.index &&
        this.gallery.some((img) => img.index === this.galleryIndex)
      ) {
        return this.gallery.find((m) => m.index === this.galleryIndex)
          .public_url;
      }
      return media.scope === "video" ? media.preview_img : media.public_url;
    },
    selectImage(index) {
      clearInterval(this.timeoutStarted.theTimeout);
      this.timeoutStarted.theTimeout = setTimeout(() => {
        this.galleryIndex = index;
        this.sendInterval = 0;
      }, 200);
    },

    mouseOut(index) {
      this.galleryIndex = index;
      this.sendInterval = 4000;
      this.$refs["miniOfferSlider" + this.index].setData({
        index: this.galleryIndex,
        interval: 4000
      });
    },
    showLightbox(index) {
      this.$refs["miniOfferSlider" + this.index].showModalGallery(
        "none",
        this.getMediaOffer,
        index
      );
    },

    sliderChanged(data) {
      this.galleryIndex = data.index;
    }
  },
  watch: {
    selectedVariantIndex(newVal, oldVal) {
      if(newVal !== oldVal) {
        this.galleryIndex = 0;
      }
    }
  }
};
</script>

<style scoped>
.expanded_checkbox {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.expanded_checkbox:focus ~ label {
  outline: -webkit-focus-ring-color auto 5px;
}

.offer-header >>> .custom-carousel-controls {
  z-index: 100;
}
</style>