<template>
  <div
    @mouseenter="mouseIn = true"
    @mouseleave="mouseIn = false"
    class="block-header-gallery"
  >
    <logo
      v-if="logoDataComputed"
      :logoData="logoDataComputed"
      :logoPosition="logoConfigComputed"
    />
    <div
      v-if="canShowSoundBtn"
      class="audio rounded-circle"
      @click="audioControl()"
    >
      <div class="audio-icons-wrapper">
        <div class="audio-tooltip" v-b-tooltip.hover.bottom.v-secondary="$t('page.gallery.mute_audio')">
          <b-icon
            class="muted mb-0"
            icon="volume-up"
          />
        </div>
        <div class="audio-tooltip" v-b-tooltip.hover.bottom.v-secondary="$t('page.gallery.unmute_audio')">
          <b-icon
            class="unmuted mb-0"
            icon="volume-mute"
          />
        </div>
      </div>
    </div>
    <b-carousel
      fade
      @sliding-start="slideEnter($event)"
      @sliding-end="slideEnd()"
      :interval="headerCarouselInterval"
      :controls="galleryData.length > 1"
      class="ynv-core-carousel overflow-hidden"
      ref="headerCarousel"
      v-model="slide"
    >
      <b-carousel-slide
        v-for="item in galleryData"
        :key="item.public_id"
        class="header-slide"
        :class="{
          'custom-italic': 'italic' in styleData && styleData.italic,
          'custom-bold': 'bold' in styleData && styleData.bold,
          'type-video': item.type == 'video'
        }"
        :caption="title"
      >
        <!--VIDEO HTML-->
        <template v-slot:img>
          <template v-if="item.type === 'video'">
            <videoPlayer
              v-if="item.provider === 'cdn'"
              class="video-wrapper"
              :ref="`cdn${item.public_id}`"
              :options="{
                sources: [
                  {
                    type: 'video/mp4',
                    src: item.public_url
                  }
                ],
                autoplay: true,
                ...options
              }"
              @ended="onEnded('cdn')"
            />
            <!-- YOUTUBE & VIMEO ---->
            <div class="video-background">
              <YoutubePlayer
                v-if="item.provider === 'youtube'"
                :player-id="`youtube${item.public_id}`"
                :ref="`youtube${item.public_id}`"
                :videoId="item.public_id"
                :loop="Number(galleryData.length === 1)"
                :autoplay="canAutoplay() ? 1 : 0"
                @ended="onEnded('youtube')"
              />
              <vueVimeoPlayer
                v-else-if="item.provider === 'vimeo'"
                allow="autoplay"
                :options="{
                  autoplay: canAutoplay('vimeo'),
                  ...options
                }"
                :loop="false"
                :ref="`vimeo${item.public_id}`"
                :video-id="item.public_id"
                @ended="onEnded('vimeo')"
              />
            </div>
          </template>
          <template v-else>
            <div class="preview-dummy-img">
              <div
                :style="
                  'background-image: url(' +
                  buildMediaURL(item.public_url, item.provider, '50x50') +
                  ')'
                "
                class="img-container"
              />
            </div>
            <div
              :style="{
                'background-image':
                  'url(' +
                  buildMediaURL(
                    item.public_url,
                    item.provider,
                    returnImgWidth()
                  ) +
                  ')'
              }"
              class="image-wrapper"
            />
          </template>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
import Logo from "@/components/blocks/header/Logo";
import { lpMixins } from "@/mixins/lpMixins";
import YoutubePlayer from "@/components/youtubePlayer/YoutubePlayer";
import { vueVimeoPlayer } from "vue-vimeo-player";
import { videoPlayer } from "vue-video-player";

import "video.js/dist/video-js.css";

export default {
  name: "Slider",
  components: { Logo, YoutubePlayer, vueVimeoPlayer, videoPlayer },
  mixins: [lpMixins],
  props: ["galleryData", "title", "account", "styleData", "configData", "audioColor"],
  data() {
    return {
      options: {
        muted: true,
        loop: false,
        preload: true,
        playsinline: true,
        controls: false
      },
      slide: 0,
      mouseIn: false,
      muteVideo: true,
      prevSlide: null,
      audioIconColor: this.isTooDark(this.audioColor)
    };
  },
  computed: {
    mute() {
      return this.muteVideo ? "none" : "block";
    },
    unmute() {
      return !this.muteVideo ? "none" : "block";
    },
    logoDataComputed() {
      return (
        (this.account && this.account.logo && this.account.logo.data) || null
      );
    },
    logoConfigComputed() {
      return (
        (this.configData &&
          "header" in this.configData &&
          this.configData.header &&
          "logo" in this.configData.header &&
          this.configData.header.logo) ||
        {}
      );
    },
    headerCarouselInterval() {
      return this.currentSlide.type === "video" || this.mouseIn ? 0 : 5000;
    },
    currentSlide() {
      return this.galleryData[this.slide];
    },
    canShowSoundBtn(){
      return this.currentSlide?.type === 'video' && [null,1].includes(this.currentSlide?.has_audio)
    },
    isNormalVideo() {
      return (
        this.currentSlide.type === "video" &&
        this.currentSlide.provider === "cdn"
      );
    },
    isVimeoVideo() {
      return this.currentSlide.provider === "vimeo";
    },
    isYoutubeVideo() {
      return this.currentSlide.provider === "youtube";
    },
    refName() {
      return this.currentSlide.provider + this.currentSlide.public_id;
    },
    player() {
      return this.$refs[this.refName][0].player;
    }
  },
  methods: {
    audioControl() {
      this.muteVideo = !this.muteVideo;
      this.audioSettings(this.player, this.currentSlide.provider);
    },
    audioSettings(player, provider) {
      if (provider === "cdn") {
        player.muted(this.muteVideo);
      } else if (provider === "vimeo") {
        player.setMuted(this.muteVideo);
      } else if(provider === "youtube") {
        this.muteVideo ? player.mute() : player.unMute();
      }
    },
    canAutoplay(provider = "youtube") {
      return !!(
        this.galleryData.length === 1 ||
        this.galleryData[0].provider === provider
      );
    },
    onEnded(el) {
      if (this.currentSlide.provider !== el) return;
      this.$refs.headerCarousel.next();
      if (this.galleryData.length === 1) {
        if (this.isNormalVideo || this.isVimeoVideo) {
          this.player.play();
        } else this.player.playVideo();
        return;
      }
      // Stop the video after ended event triggered if there is more than element at carousel
      if (this.isYoutubeVideo) {
        this.player.stopVideo();
      } else if (this.isNormalVideo) {
        this.player.load();
      }
    },
    slideEnd() {
      this.muteVideo = true;
      const {
        provider,
        public_id,
        type: prevType
      } = this.galleryData[this.prevSlide];
      if (prevType === "video") {
        const refPreviousSlide = this.$refs[provider + public_id][0].player;
        this.audioSettings(refPreviousSlide, provider);
      }

      const { type } = this.currentSlide;
      if (type === "image") return;
      if (this.isYoutubeVideo) {
        this.player?.playVideo();
      } else {
        this.player.play();
      }
    },
    slideEnter(e) {
      this.prevSlide = this.slide;
      this.slide = e;
      if (this.isYoutubeVideo) {
        this.player.stopVideo();
      }
      if (this.isVimeoVideo) {
        this.$refs[this.refName][0].update(this.currentSlide.public_id);
      }

      this.slide = e;
      if (!this.mouseIn) this.mouseIn = false;
    }
  }
};
</script>

<style scoped>
/*extra small - small*/
@media only screen and (max-width: 767px) {
  .ynv-core-carousel .header-slide,
  .ynv-core-carousel .header-slide.type-video,
  .ynv-core-carousel .carousel-item .video-wrapper {
    height: 56.23vw !important;
  }
}

/*medium*/
@media (min-width: 768px) and (max-width: 991px) {
  .ynv-core-carousel .header-slide,
  .ynv-core-carousel .header-slide.type-video,
  .ynv-core-carousel .carousel-item .video-wrapper {
    height: 56.23vw !important;
  }
}

/*large*/
@media (min-width: 992px) and (max-width: 1199px) {
  .ynv-core-carousel .header-slide,
  .ynv-core-carousel .header-slide.type-video,
  .ynv-core-carousel .carousel-item .video-wrapper {
    height: 56.23vw !important;
  }
}

/*extra large*/
@media (min-width: 1200px) {
  .ynv-core-carousel .header-slide,
  .ynv-core-carousel .header-slide.type-video,
  .ynv-core-carousel .carousel-item .video-wrapper {
    height: 50vw !important;
  }
}

.ynv-core-carousel .header-slide,
.ynv-core-carousel .header-slide.type-video,
.ynv-core-carousel .carousel-item .video-wrapper {
  max-width: inherit;
}

.video-wrapper,
.video-item {
  height: auto !important;
}

.video-background {
  width: 100% !important;
  height: 100% !important;
}

.video-background iframe {
  height: 100% !important;
  width: 100% !important;
}

>>> iframe#youtube-vue-player-soma {
  height: 100% !important;
  width: 100% !important;
}

>>> .vjs_video_395-dimensions {
  width: 100%;
  height: 100%;
}

>>> .video-background:first-child,
>>> .video-background:first-child iframe {
  width: 100% !important;
  height: 100% !important;
  background-color: black;
}

>>> .video-js {
  width: 100%;
  height: 100%;
}

/* Audio button + position */
.audio {
  position: absolute;
  background-color: v-bind(audioColor);
  z-index: 3;
  line-height: 0;
  cursor: pointer;
  user-select: none;
}

.muted {
  display: v-bind(mute) !important;
}

.unmuted {
  display: v-bind(unmute) !important;
}

.muted, .unmuted {
  user-select: none;
  color: v-bind(audioIconColor);
}

.audio-icons-wrapper {
  position: relative;
}

.audio-tooltip {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media only screen and (max-width: 769px) {
  .audio {
    bottom: 15px;
    padding: 5px;
    right: 28px;
  }

  .muted, .unmuted, .audio-icons-wrapper {
    width: 22px;
    height: 22px;
  }
}

@media only screen and (min-width: 770px) {
  .audio {
    top: 15px;
    padding: 8px;
    right: 39px;
  }

  .muted, .unmuted, .audio-icons-wrapper {
    width: 24px;
    height: 24px;
  }
}
</style>